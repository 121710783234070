ul.products {
  display: flex;
  list-style: none;
  padding-left: 0;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  white-space: normal;

  @for $i from 0 through 5 {
    &.columns-#{$i} {
      flex-direction: row;
      flex-wrap: wrap;

      li.product {
        position: relative;
        @include media-breakpoint-up(lg) {
          $gutter: 20px;
          width: calc((100% - (#{$gutter} * (#{$i} - 1) )) / #{$i});
          &:not(.last) {
            margin-right: $gutter;
          }

          &.last {
            margin-right: 0;
          }
        }
      }
    }
  }

  &:not(.columns-*) {
    flex-direction: column;
  }

  li.product {
    width: 100%;
    position: relative;
    margin-bottom: $spacer * 2;

    @include media-breakpoint-up(sm) {
      display: flex;
      width: calc(50% - 5px);
      &:nth-child(odd) {
        margin-right: 10px;
      }
    }

    a {
      display: flex;
      flex-direction: column;
      width: 100%;

      img {
        display: block;
        max-width: 100%;
        max-height: 170px;
        width: 100% !important;
        object-fit: cover;
        @include media-breakpoint-up(md) {
          max-height: 200px;
        }
        height: auto;
        margin-bottom: $spacer * 0.5;
      }

      h2 {
        //@include media-breakpoint-up(md) {
          @extend .h4-bigger, .h4;
        //}
        margin-bottom: 0;
        @include media-breakpoint-down('sm') {
          font-size: 1.4rem;
        }
      }
    }

    .c-favorite-modal {
      right: 5px;
      top: 5px;
    }
  }

  & + nav.woocommerce-pagination {
    white-space: normal;

    ul {
      margin-top: 0;
    }
  }
}
