/*
  Project: Vivafloors
  Author: Thomas
 */

.u-background-margin {
  @include media-breakpoint-up('md') {
    margin-left: 20px;
    margin-right: 20px;
    //margin-bottom: 20px;
  }
}

.u-background-push {
  @include media-breakpoint-up(md) {
    margin-top: -60px;
    padding-top: 60px;
  }
}

.u-background-push-2 {
  @include media-breakpoint-up(md) {
    position: relative;
    z-index: 1;
    background: $light;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 48%;
      left: 0;
      height: 52.5%;
      background: white;
      width: 100%;
      z-index: -1;
    }
  }
}


.u-background-push-3 {
  @include media-breakpoint-down(sm) {
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 50%;
      background: $light;
      width: 100%;
      z-index: -1;
    }
  }
}

.u-background-push-4 {

  position: relative;
  z-index: 1;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    background: white;
    width: 100%;
    z-index: -1;
    top: 84%;
    height: 16%;
  }

  @include media-breakpoint-up(md) {
    &:after {
      top: 79%;
      height: 21%;
    }
  }
}

.u-background-push-5 {
  @include media-breakpoint-up(md) {
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 77%;
      left: 0;
      height: 24%;
      background: white;
      width: 100%;
      z-index: -1;
    }
  }
}

.u-background-push-6 {
  @include media-breakpoint-up(md) {
    position: relative;
    z-index: 1;
    background: $light;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 52%;
      background: white;
      width: 100%;
      z-index: -1;
    }
  }
}

.u-background-push-product {
  @include media-breakpoint-up(md) {
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: $spacer * 3;
      background: white;
      width: 100%;
      z-index: -1;
    }
  }
}