.container-lg {
    @include media-breakpoint-up('lg') {
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
        margin-left: auto;
        margin-right: auto;
        max-width: map-get($map: $container-max-widths, $key: 'lg');
    }

    @include media-breakpoint-up('xl') {
        max-width: map-get($map: $container-max-widths, $key: 'xl');
    }
}

.container-down-lg {    
    @include media-breakpoint-down('md') {
        padding-left: 40px;
        padding-right: 40px;
        max-width: map-get($map: $container-max-widths, $key: 'md');
    }

    @include media-breakpoint-down('sm') {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        max-width: map-get($map: $container-max-widths, $key: 'sm');
    }
}