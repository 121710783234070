/*
 * Custom AJAX spinner on WooCommerce checkout
 * The class used to load the overlay is .blockUI .blockOverlay
 * The class used to load the spinner is .woocommerce .loader:before
 *
 */
.woocommerce .blockUI.blockOverlay:before,.woocommerce .loader:before {
  height: 3em;
  width: 3em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -.5em;
  margin-top: -.5em;
  display: block;
  content: "";
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
  background: url(../assets/images/ajax-loader.gif) no-repeat;
  background-size: 32px auto;
  line-height: 1;
  text-align: center;
  font-size: 2em;
}
