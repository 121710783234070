/*
  Project: Vivafloors
  Author: Thomas
 */

.c-hero {


  &_coltext {
    @include media-breakpoint-only(md) {
      flex: 0 0 47%;
      max-width: 47%;
    }

    &_title {
      @include media-breakpoint-only(md) {
        font-size: 2.6rem;
      }
    }
  }

  &_image {
    @include media-breakpoint-down(sm) {
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        height: 50%;
        background: $gray-lighter;
        width: 100%;
        z-index: -1;
      }
    }
    //@include media-breakpoint-up(md) {
    //  img {
    //    height: 100% !important;
    //    width: auto !important;
    //  }
    //}
  }
}
