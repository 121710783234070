/*
  Project: Vivafloors
  Author: Thomas
 */

.btn {
  font-family: $font-family-ttcommons-demibold;
  white-space: normal;

  &-icon {
    svg {
      transform: translate(0, 3px);
    }
  }

  &-heart {
    svg {
      transform: translate(0, 5px);
    }

    path {
      fill: white;
    }
  }

  &-call {
    svg {
      transform: translate(0, 5px);
    }

    path {
      fill: white;
    }
  }

  &:hover {
    text-decoration: none;

    &>* {
      text-decoration: none;
      border-bottom: 0;
    }
  }

  @include media-breakpoint-down('xs') {
    &-xs-block {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
}

.btn-wrapper {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down('md') {
    display: inline-flex;
  }

  .btn-micro {
    text-align: center;
    margin-top: $spacer * 0.5;
    font-size: $font-size-sm;
    color: $cherry;
  }
}