/*
  Project: Vivafloors
  Author: Thomas
 */

.c-block-list {
  &_nr {
    height: 27px;
    width: 27px;
    display: block;
    text-align: center;
    line-height: 27px;
    border-radius: 50%;
    color: $gray-middle;
    border: solid 2px $gray-middle;
    font-size: $font-size-sm;
    font-family: $font-family-ttcommons-bold;
    transform: translateY(-4px);
  }

  &_image {
    @include media-breakpoint-down(sm) {
      padding: 0 20%;
    }
  }
}
