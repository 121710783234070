.bg-none {
  background-color: transparent;
}

.bg-md-none {
  @include media-breakpoint-up(md) {
    background-color: transparent;
  }
}

.bg-lg-none {
  @include media-breakpoint-up(lg) {
    background-color: transparent;
  }
}

.bg-lg-gray-100 {
  @include media-breakpoint-up(lg) {
    background-color: $gray-100;
  }
}

.bg-md-gray-100 {
  @include media-breakpoint-up(md) {
    background-color: $gray-100;
  }
}
