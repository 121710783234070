// negative margins

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $margin in map-keys($spacers) {
            $value: map-get($spacers, $margin);

            .-mt#{$infix}-#{$margin} {
                margin-top: -$value;
            }

            .-mb#{$infix}-#{$margin} {
                margin-bottom: -$value;
            }

            .-mr#{$infix}-#{$margin} {
                margin-right: -$value;
            }

            .-ml#{$infix}-#{$margin} {
                margin-left: -$value;
            }
        }
    }
}

