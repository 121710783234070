.u-hide-in-footer {
  footer & {
    display: none;
  }
}

.u-hide-hypothesis-1,
.u-hide-hypothesis-2,
.u-hide-hypothesis-5
{
  display: none;
}

//// TODO Change to body.show-all-prices
//body.show-all-prices {
//  .show-prices-element {
//    display: block !important;
//  }
//}
