.c-dealers {
    &-header {
        [data-cookieconsent="0"] & {
            opacity: 0.3 !important;
            input,
            label,
            a {
                pointer-events: none;
            }
        }
    }

    &-form {
        input[type="radio"] {
            /* The native appearance is hidden */
            appearance: none;
            -webkit-appearance: none;

            /* For a circular appearance we need a border-radius. */
            border-radius: 50%;

            /* The background will be the radio dot's color. */
            background: $cherry;

            /* The border will be the spacing between the dot and the outer circle */
            border: 5px solid $gray-400;

            /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
            box-shadow: 0 0 0 1px $gray-400;

            &+label {
                color: $gray-600;
            }

            &:checked {
                border: 5px solid $cherry;

                &+label {
                    color: $cherry;
                }
            }
        }
    }

    &-all-results {
        // Reserve some space
        min-height: 300px;

        @include media-breakpoint-up(lg) {
            margin-top: -80px;
            max-height: initial;
        }
    }

    &-google-maps-wrapper {
        position: relative;
    }

    &-results-wrapper {
        position: relative;

        @include media-breakpoint-up('lg') {
            position: absolute;
            left: 40px;
            top: 60px;
            bottom: 20px;
            height: calc(100% - 80px);
            padding-top: map-get($spacers, 3);
            padding-bottom: map-get($spacers, 3);
            margin: 0 !important;
            width: calc(50% - 40px);

            &>.container-sm-md {
                height: 100%;
            }
        }

        @media (min-width: 1100px) {
            left: calc((100% - 1020px) * 0.5 );
        }

        @include media-breakpoint-up('xl') {
            left: calc((100% - 1060px) * 0.5 );
        }

        @media (min-width: 1550px) {
            top: 70px;
            bottom: 30px;
            height: calc(100% - 100px);
            width: #{map-get($container-max-widths, 'lg') * 0.65};
        }
    }

    &-results {
        @include media-breakpoint-up(lg) {
            overflow-y: auto;
            height: calc(100% - 60px);
        }
    }

    &-loading {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        margin: auto;

        @keyframes rotate {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        svg {
            width: 80px;
            height: 80px;
        }

        svg {
            animation: rotate 1s infinite;
            transform-origin: 50% 50%;
        }

        @include media-breakpoint-up(lg) {
            top: calc(50% - 25px);
        }
    }

    &-floor-search-wrapper {
        width: 100%;
        display: none !important;

        @include media-breakpoint-up(md) {
            max-width: 275px;
        }

        [data-active-filter="floors"] & {
            display: flex !important;
        }
    }

    &-floor-search+.select2 {
        width: 100% !important;
        z-index: 10;

        .select2-selection {
            @extend .btn;
            @extend .btn-xs-block;
            @extend .btn-outline-primary;

            display: flex;
            flex-direction: row;
            height: auto;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-up(md) {
                justify-content: flex-start;
            }

            // Needed for overrule open dropdown
            border-bottom-left-radius: 24px !important;
            border-bottom-right-radius: 24px !important;
            border-top-left-radius: 24px !important;
            border-top-right-radius: 24px !important;

            .select2-selection__rendered {
                color: $cherry;
            }

            .select2-selection__arrow {
                position: initial;
                width: 17px;
                height: 10px;
                background: url(../assets/images/icons/chevron-down-cherry.svg) no-repeat;
                background-size: cover;
                will-change: transform;
                transform: rotate(0deg);
                transition: transform 0.25s ease-in-out;
                display: flex;

                @include media-breakpoint-up(md) {
                    margin-left: auto;
                }

                b {
                    display: none;
                }
            }
        }

        &.select2-container--open {
            .select2-selection {
                background-color: $cherry !important;
            }

            .select2-selection__rendered {
                color: $white;
            }

            .select2-selection__arrow {
                transform: rotate(180deg);
                background: url(../assets/images/icons/chevron-down-white.svg) no-repeat;
            }
        }
    }

    &-location {
        &.active {
            border-color: $gray-500 !important;
            pointer-events: none;

            path {
                fill: $gray-500;
            }
        }
    }

    &-google-maps {
        aspect-ratio: 3 / 4;

        @include media-breakpoint-up(md) {
            aspect-ratio: 16 / 9;
        }

        @include media-breakpoint-up(lg) {
            aspect-ratio: 16 / 11;
        }

        @include media-breakpoint-up(xl) {
            aspect-ratio: 16 / 9;
        }

        @include media-breakpoint-up(xxl) {
            aspect-ratio: 16 / 9;
        }

        @media (min-width: 1600px) {
            aspect-ratio: 16 / 6;
        }

        // Info window
        .gm-style-iw {
            padding: 12px !important;
            border-radius: 3px !important;

            @include media-breakpoint-up(md) {
                width: 300px !important;
            }
            
            @include media-breakpoint-up(lg) {
                width: 350px !important;
            }

            &>div {
                overflow: auto !important;
            }

            // Cross button
            .gm-style-iw-chr {
                overflow: visible !important;

                .gm-style-iw-ch {
                    padding: 0 !important;
                }

                button {
                    width: 25px !important;
                    height: 25px !important;

                    span {
                        width: 25px !important;
                        height: 25px !important;
                        margin: 0 !important;
                    }
                }
            }

            // Content
            .gm-style-iw-d {
                padding: 12px !important;
            }
        }

        &-link {
            font-size: 1rem;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        [aria-label="Cluster"] {
            width: 35px !important;
            height: 35px !important;
            margin-left: 8px !important;
            margin-top: 8px !important;

            img {
                width: 35px !important;
                height: 35px !important;
            }
        }
    }

    &-card {
        @include media-breakpoint-up(lg) {
            &:first-child {
                margin-top: 0 !important;
            }
        }

        &_premium {
            position: relative;

            &::after {
                display: block;
                content: '';
                position: absolute;
                right: -6px;
                top: 0;
                width: 6px;
                height: 125%;
                background: url(../assets/images/premium-side.svg) no-repeat;
                background-size: cover;

                @include media-breakpoint-up(md) {
                    left: -6px;
                    right: initial;
                    transform: scaleX(-1);
                    transform-origin: center;
                }
            }

            .star {
                margin-top: 6px;
            }
        }

        &.hide {
            display: none !important;
        }

        &_floors {
            display: none !important;

            [data-active-filter="floors"] & {
                display: flex !important;
            }

            &_remainder {
                margin-top: 0.5rem;
                font-size: $font-size-xs;
                color: $cherry;
                background-color: $gray-200;
                line-height: 1.8;
                padding: 0 5px;
                border-radius: 4px;
            }
        }

        &_checks {
            display: flex !important;

            [data-active-filter="floors"] & {
                display: none !important;
            }

            [data-active-filter="stairs"] & {
                .stairrenovation {
                    color: $cherry;
                }
            }
        }

        .busy & {
            opacity: 0.3;
        }
    }

    &-btn-icon {
        &__calendar {
            @include media-breakpoint-up(lg) {
                height: 14px;
            }
        }

        &__chevron {
            @include media-breakpoint-up(lg) {
                height: 12px;
            }
        }
    }
}

.select2-dropdown {
    padding: 10px !important;

    &--below {
        top: 8px !important;
    }

    &--above {
        top: -8px !important;
    }

    .select2-results__group {
        font-size: 1rem;
    }

    .select2-search {
        position: relative;

        input {
            padding-left: 40px !important;
        }

        &:before {
            display: block;
            content: '';
            position: absolute;
            left: 12px;
            top: 12px;
            width: 22px;
            height: 22px;
            background: url(../assets/images/icon-search.svg) no-repeat;
            background-size: cover;
        }
    }
}