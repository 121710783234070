@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      @for $i from 0 through 10 {
        .z#{$infix}-#{$i * 10} {
          z-index: ($i * 10) !important;
        }
      }
    }
  }
  