/*
  Project: Vivafloors
  Author: Thomas
 */

.u-icon {
  &_check {
    padding-left: 24px;
    position: relative;
    &:before {
      position: absolute;
      left: 0;
      top: 2px;
      content: '';
      width: 16px;
      height: 12px;
      background: url('../assets/images/icon-check.svg') no-repeat;
      background-size: cover;
    }
  }

  &_bulb {
    padding-left: 24px;
    position: relative;
    &:before {
      position: absolute;
      left:1.5em;
      top: 1.3em;
      content: '';
      width: 14px;
      height: 22px;
      background: url('../assets/images/icon-bulb.svg') no-repeat;
      background-size: cover;
    }
  }
}
