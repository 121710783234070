.c-wpsl {

  html &#wpsl-wrap {
    margin-bottom: 0 !important;
  }

  form {
    background: $gray-lighter;
    padding: 1rem 1rem 1.5rem;
    border-bottom: white 4px solid;

    @include media-breakpoint-up(sm) {
      padding: 1rem 2rem 1.5rem;
    }

    input {
      width: 100% !important;
    }
  }

  &__stores {
    background: $gray-lighter;
    padding: 1rem;

    @include media-breakpoint-up(sm) {
      padding: 1rem 2rem;
    }

    ul {
      li {
        list-style: none;
        border-bottom: 2px solid $white;
        padding-bottom: 0.75rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(sm) {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }

        .wpsl-store-location {
          margin-bottom: 15px;
          text-align: left;

          @include media-breakpoint-up(sm) {
            margin-bottom: 0;
          }
        }

        .wpsl-actions {
          margin-bottom: 0;

          @include media-breakpoint-up(sm) {
            margin-left: 20px;
          }
        }
      }
    }
  }
}