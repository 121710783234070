.c-steps {
  width: 100%;

  h3 {
    margin-bottom: $spacer * 2;
  }

  &-border {
    border: 2px solid $black;
    border-radius: 4px;
    padding: ($spacer * 2) ($spacer);

    @include media-breakpoint-up(md) {
      padding: ($spacer * 2) ($spacer * 1.5);
    }

    @include media-breakpoint-up(lg) {
      padding: ($spacer * 2) ($spacer * 3);
    }
  }
}
