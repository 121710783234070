.c-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // overflow-y: auto;
  // padding: $spacer;
  transition: all 0.4s ease-in-out;
  visibility: hidden;

  @include media-breakpoint-down(md) {
    z-index: 999;
    transform: translateX(100%);
    background-color: $white;

    &.active {
      transform: translateX(0);
    }
  }

  @include media-breakpoint-up(md) {
    background: transparent;
    z-index: -1;

    &.active {
      z-index: 999;
      background-color: rgba($gray-light, 0.9);
    }
  }

  .datepicker {
    width: 100% !important;
  }

  &__step {
    transition: opacity 0.35s ease-in-out;
    opacity: 0;
    height: 0;
    overflow: hidden;

    &.active {
      opacity: 1;
      height: auto;
    }
  }

  &.active {
    visibility: visible;

    .c-modal__content-loader {
      visibility: visible;
    }

    .c-modal__content {
      opacity: 1;
      visibility: visible;
    }
  }

  &.loaded {
    .c-modal__content-loader {
      visibility: hidden;
    }

    .c-modal__content-body {
      opacity: 1;
    }

    .c-modal__content {
      @include media-breakpoint-up(md) {
        transform: translate(-50%, -50%) scale(1, 1);
      }
    }

  }

  &__content {
    visibility: hidden;
    position: relative;
    padding: 0;

    @include media-breakpoint-up(md) {
      width: 668px;
      min-height: 300px;
      border-radius: $border-radius-sm;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.8, 0.8);
      box-shadow: $box-shadow-lg;
      transition: transform 0.35s ease-in-out;
    }

    &-checkboxes {
      @include media-breakpoint-up(sm) {
        width: 75%;
        margin: 0 auto;
      }
    }

    &-loader {
      visibility: hidden;
      right: 0;
      height: 60px;
      width: 60px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      background: url(../assets/images/ajax-loader.gif) white no-repeat center center;
      border-radius: 4px;
    }

    &-header {
      display: flex;
      flex-direction: row;
    }

    &-image {
      margin-left: -15px;
      height: 150px;
      width: auto;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        img {
          object-fit: cover;
          width: 100%;
        }
      }

      @include media-breakpoint-up(md) {
        margin-left: 0;
        height: 200px;
        width: auto;
      }
    }

    &-body {
      opacity: 0;
      transition: opacity 0.4s ease-in-out;
      overflow-y: auto;
      overflow-x: hidden;
      padding: ($spacer * 1) 0;
      background: white;
      padding: $spacer 1.5 * $spacer;
      max-height: calc(100vh - 150px);

      @include media-breakpoint-up(sm) {
        margin-top: 0;
        padding: ($spacer * 2) ($spacer * 2.5);
        max-height: calc(90vh - 200px);
      }

      &-intro {
        width: 100%;
        overflow: auto;
      }
    }

    &-ctas {
      width: 60%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }

    &-product {
      width: 60%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
  }

  &__close {
    position: absolute;
    right: 10px;
    top: $spacer;
    background: white;
    border-radius: 5px;
    border: none;
    color: $cherry;

    @include media-breakpoint-up(md) {
      top: -2 * $spacer;
      background: transparent;
      right: 0;
    }

    cursor: pointer;
    display: -webkit-flex;
    /* Safari */
    display: flex;
    -webkit-align-items: center;
    /* Safari 7.0+ */
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;

    & span {
      margin-right: 6px;
    }
  }

  .js-dealermodal-step {
    &[data-step="0"] {
      .gfield_radio {
        display: flex;
        flex-direction: column;
        align-items: center;

        &>div {
          display: flex;
          flex-direction: row;

          &:not(:last-child) {
            margin-bottom: 5px;
          }

          input {
            margin-right: 5px;
            margin-top: 5px;

            @include media-breakpoint-up(md) {
              margin-top: 7px;
            }
          }

          label {
            text-align: left;
          }
        }
      }
    }

    &[data-step="2"] {
      .js-dealer-form {
        background-color: $gray-100;
        padding: 40px 20px 20px;
        margin-top: 20px;

        @include media-breakpoint-up(md) {
          margin-top: 40px;
        }

        .gform_title {
          font-size: 1.9rem;
          margin-bottom: 2rem;

          @include media-breakpoint-up(md) {
            font-size: 2.2rem;
          }
        }

        .gfield_checkbox {
          text-align: left;

          @include media-breakpoint-up(md) {
            text-align: center;
          }

          input {
            margin-right: 10px;
            vertical-align: top;
            margin-top: 7px;
          }
        }

        fieldset {
          &>div {
            span {
              margin-bottom: 15px;

              @include media-breakpoint-up(sm) {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }

  .gf_progressbar_percentage {
    display: none;
  }

  .gf_progressbar_title {
    color: $gray-middle;
  }

  .gform_button {
    width: auto;
  }
}