/*
  Project: Vivafloors
  Author: Thomas
 */

.c-action-link {
  padding-right: 40px;
  position: relative;
  width: auto;
  display: inline-flex;
  font-weight: $font-weight-base;
  font-size: $font-size-base;
  align-items: center;
  color: $cherry;
  border: none;
  background: none;

  &:after {
    content: '';
    width: 25px;
    height: 15px;
    display: block;
    position: absolute;
    right: 0;
    top: calc(50% - 8px);
    background: url('../assets/images/icon-arrow-more.svg');
    background-size: cover;
    transition: transform 0.25s ease-in-out;
  }

  &.c-action-link__left {
    padding-right: 0;
    padding-left: 40px;

    &:after {
      right: inherit;
      left: 0;
      transform: rotate(180deg);
    }

    &:hover {
      &:after {
        transform: rotate(180deg) translateX(5px);
      }
    }
  }

  &:hover {
    text-decoration: none;

    * {
      text-decoration: none;
      border: 0;
    }

    &:after {
      transform: translateX(5px);
    }

    * > {
      border-bottom: 0;
    }
  }

  &-no-arrow {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    color: $cherry;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &:after {
      display: none;
    }
  }
}

