ul {
    &.checks {
        list-style-type: none;

        li {
            position: relative;
            padding-left: 25px;
            margin-bottom: 0.3rem;

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                position: absolute;
                left: 0;
                top: 5px;
                content: '';
                width: 18px;
                height: 14px;
                background: url(../assets/images/icon-check.svg) no-repeat;
                background-size: cover;
            }

            @include media-breakpoint-up('lg') {
                margin-bottom: 0.2rem;

                &::before {
                    width: 14px;
                    height: 11px;
                }
            }
        }
    }
}