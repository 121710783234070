.main-breadcrumbs {
  color: $gray-middle;

  a {
    &:hover {
      color: $cherry;
    }
  }

  a,
  span.breadcrumb_last {
    color: $gray-middle;
    margin: 0 10px;

    @include media-breakpoint-up(md) {
      margin: 0 4px;
    }

    @include media-breakpoint-up(lg) {
      margin: 0 6px;
    }

    display: inline-block;
  }

  >span>span>a {
    margin-left: 0;
  }

  .breadcrumb_last {
    font-weight: bold;
  }
}

.breadcrumb-back {
  position: relative;
  padding: 6px 16px 5px 38px;
  background: transparent;
  color: $cherry;
  border-radius: 20px;
  cursor: pointer;
  transition: $transition-base;
  font-size: $font-size-sm;
  border: 1px solid $gray-400;

  &:focus {
    outline: none;
  }

  &:hover {
    border: 1px solid $cherry;
  }

  &:before {
    content: '';
    width: 7px;
    height: 12px;
    display: block;
    position: absolute;
    left: 13px;
    top: 50%;
    background: url(../assets/images/icons/chevron-right.svg) no-repeat;
    background-size: 100%;
    transition: transform .25s ease-in-out;
    transform: translateY(-50%) rotate(180deg);
  }
}