.c-tease-search {
  &__image {
    width: 60px;
    @include media-breakpoint-up(sm) {
      width: 90px;
    }
    @include media-breakpoint-up(md) {
      width: 150px;
    }
  }
}
