@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  
      .text#{$infix}-base {
        font-size: $font-size-base !important;
      }
  
      .text#{$infix}-xs {
        font-size: $font-size-xs !important;
      }

      .text#{$infix}-sm {
        font-size: $font-size-sm !important;
      }
  
      .text#{$infix}-lg {
        font-size: $font-size-lg !important;
      }
  
      .text#{$infix}-xl {
        font-size: $font-size-xl !important;
      }
  
      .text#{$infix}-xxl {
        font-size: $font-size-xxl !important;
      }
    }
  }