.c-header {
  width: 100%;
  background-color: $white;
  height: 62px;

  @include media-breakpoint-up(md) {
    height: 153px;
  }

  @include media-breakpoint-up(lg) {
    height: 127px;
  }

  &-mobile {
    z-index: 101;
    background-color: $white;
    padding: $spacer * 0.75 0;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    border-bottom: 1px solid $gray-200;

    body.visible-menu & {
      position: fixed;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }

    &_toggle {
      height: 30px;
      border: none;
      color: $cherry;
      background: $white;
      font-family: $font-family-ttcommons-demibold;
      display: flex;
      flex-direction: row;
      align-items: center;
      outline: none !important;
      margin: $spacer * 0.25 $spacer * 0.25 0 $spacer * 0.75;
      padding: 0;

      &:first-child {
        margin-left: 0;
      }
    }

    &_favorite_text {
      display: none;

      @media (min-width: 375px) {
        display: block;
      }
    }
  }

  &-desktop {
    display: none;

    @include media-breakpoint-up(md) {
      border-bottom: 1px solid $gray-200;
      display: block;
      position: fixed;
      z-index: 101;
      background-color: $white;
      left: 0;
      top: 0;
      width: 100%;
      transform: translateY(-46px);
      transition: transform 0.25s ease-in-out;

      .sticky & {
        transform: translateY(0);
        border-bottom: none;
      }
    }

    &-submenu {
      @include media-breakpoint-up(md) {
        height: 46px;
      }
    }
  }
}

body.admin-bar .c-header-desktop {
  margin-top: 32px;
}