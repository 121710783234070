.c-tabs {
  @include media-breakpoint-up(lg) {
    margin-bottom: $spacer * 4;
  }

  h2 {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      margin-bottom: $spacer * 2;
    }
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacer * 2;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: flex-start;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    & > a {
      display: flex;
      flex-direction: row;
      background-color: $gray-200;
      padding: $spacer * 0.75;
      border-radius: $border-radius-sm;
      align-items: center;
      margin-bottom: $spacer * 0.5;

      @include media-breakpoint-up(md) {
        &:not(:first-child) {
          margin-left: $spacer * 0.5;
        }
      }

      @include media-breakpoint-up(lg) {
        background-color: $white;
        position: relative;
        margin-bottom: 0;
        border-radius: $border-radius-sm $border-radius-sm 0 0;

        &.start-active,
        &.active {
          z-index: 3;
          color: $black;
          border: 2px solid $black;
          border-bottom-color: $white;
          margin-bottom: -2px;

          span {
            color: $black;
          }
        }
      }

      span {
        // Label
        &:first-child {
          display: block;
          padding-right: $spacer;
          font-size: $font-size-base;

          @include media-breakpoint-up(lg) {
            padding-right: 0;
            font-weight: $font-weight-bold;
          }
        }

        // Icon
        &:last-child {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: auto;
        }

        @include media-breakpoint-up(lg) {
          &:last-child {
            display: none;
          }
        }
      }

      &:hover {
        text-decoration: none;

        span {
          text-decoration: none;
          border-bottom: 0;
        }
      }
    }
  }

  &__content {
    @include media-breakpoint-up(lg) {
      position: relative;
    }

    & > div {
      display: flex;
      flex-direction: column;
      transform: translateX(100%);
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      padding: 20px;
      z-index: 999;
      background-color: $gray-100;
      transition: transform 0.25s ease-in-out;

      &.active {
        transform: translateX(0);
      }

      @include media-breakpoint-up(lg) {
        transform: translateX(0);
        position: absolute;
        background-color: $white;
        border: 2px solid $black;
        z-index: 1;
        padding: ($spacer * 3) ($spacer * 4);

        &.active,
        &.start-active {
          position: relative;
          z-index: 2;
        }
      }
    }

    &-header {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-top: $spacer * 0.5;
      padding-bottom: $spacer;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &-content {
      //display: flex;
      //flex-direction: column;
      overflow: auto;

      @include media-breakpoint-up(lg) {
        overflow: auto;
        //flex-direction: row;
      }

      & > div {
        &:not(:last-child) {
          margin-bottom: $spacer * 1.5;
        }
        @include media-breakpoint-up(lg) {
          &:first-child {
            margin-right: 50px;
          }
        }
      }

      p {
        margin-bottom: $spacer * 0.5;
      }

      .btn {
        margin-bottom: $spacer * 0.5;
        padding-left: 0;
      }
    }

    &-table {
      width: 100%;

      tr {
        &.odd {
          background: $gray-100;
        }
      }

      &-icon {
        transform: translate(-2px, 3px);
        display: inline-block;
        cursor: pointer;
      }

      &-label {
        padding: 0.3rem 0.5rem 0.3rem 1.7rem !important;

        &_with-icon {
          padding: 0.3rem 0.5rem !important;
        }
      }

      &-value {
        padding: 0.3rem 0.5rem !important;
      }

      &-info {
        background: lighten($gray-middle, 20%);
        padding: 0.5rem 0.75rem;
        p:last-of-type {
          margin-bottom: 0;
        }
      }

    }

  }
}
