.c-social {
  &_tag {
    font-family: $font-family-blackerdisplay-bold;
    font-size: 1.1rem;
  }

  &_icon {
    height: 19px;
    display: inline-block;
    padding: 5px;
    opacity: 1;
    transition: $transition-base;

    &:hover {
      opacity: 0.8;
    }
  }
}
