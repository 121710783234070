.c-tip {
  display: none;
  position: relative;
  &__close {
    position: absolute;
    right: 15px;
    top: 10px;
    opacity: 0.5;
    transition: $transition-base;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  a {
    color: $gray-middle;
    &:hover {
      text-decoration: underline;
    }
  }
}
