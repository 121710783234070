/*
  Project: Vivafloors
  Author: Thomas
 */
@include media-breakpoint-up(md) {
  html {
    font-size: 1.125rem;
  }
}

body {
  overflow-x: hidden; // hide horizontal
  position: relative;
  width: 100%;

  &.no-scroll {
    overflow-y: hidden !important;
  }
}
