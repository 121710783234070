/*
  Project: Vivafloors
  Author: Thomas
 */

.c-primary-menu {
  @include media-breakpoint-up(lg) {
    margin-left: 25px;
  }

  li {
    margin-bottom: 0;
  }

  .nav-item:hover>.nav-link {
    color: $cherry !important;
  }

  .nav-link {
    color: $gray-dark !important;
    transition: $transition-base;
    font-family: $font-family-ttcommons-medium;
    padding-top: 1rem;
    padding-bottom: 0;

    @include media-breakpoint-up(lg) {
      padding-top: 0.7rem;
      padding-bottom: 0.3rem;
    }

    &:hover {
      color: $cherry !important;
    }

    &:active {
      text-decoration: underline;
    }

    &_small {
      padding-top: 0.35rem;
      font-size: $font-size-sm;
      color: $gray-middle !important;
      padding-left: 1rem;

      @include media-breakpoint-up(lg) {
        padding-bottom: 0rem;
      }
    }
  }

  .nav-item:first-child a {
    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
  }


  // If there are submenu items only first lvl
  &_sub {
    position: relative;
    padding-right: 1.6rem;

    &:after {
      background: url(../assets/images/icon-arrow.svg) no-repeat;
      background-size: 100% auto;
      content: '';
      right: 8px;
      top: 1.5rem;
      display: block;
      position: absolute;
      width: 12px;
      height: 8px;

      @include media-breakpoint-up(lg) {
        top: 1.2rem;
      }
    }
  }

  &_hover {
    &:hover {
      >.c-primary-menu_dropdown {
        visibility: visible;
        padding-top: 5px;
        opacity: 1;
      }
    }
  }

  // The container for submenu relative is body
  &_dropdown {
    transition: $transition-base;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: auto;
    padding-top: 0;
    left: 0;
    background: $white;
    position: absolute;
    z-index: 101;
    box-shadow: 0 130px 130px rgba(0, 0, 0, 0.15);

    @include media-breakpoint-up(md) {
      top: 135px;
    }

    @include media-breakpoint-up(lg) {
      top: 100px;
    }

    &_title {
      font-family: $font-family-ttcommons-bold !important;
    }
  }


  &_footer {
    margin: 4px 0;
    font-family: $font-family-ttcommons-medium;

    .nav-link {
      padding: 0.5rem;
    }
  }
}