/*
  Project: Vivafloors
  Author: Thomas
 */

.c-secondary-menu {
  border-radius: 0 0 3px 3px;
  padding: 5px 13px 3px;
  position: relative;
  z-index: 1;

  &_left {
    border-radius: 0 0 0 3px;
    padding: 5px 0 3px 13px;

    >.nav {
      transform: translateY(-1px);
    }
  }

  &_right {
    border-radius: 0 0 3px 0;
    padding: 5px 13px 3px 0;
  }

  @include media-breakpoint-down(lg) {
    padding: 5px 5px 3px;
  }

  li {
    margin-bottom: 0;
  }

  .nav-link {
    color: $gray-middle !important;
    font-size: $font-size-xs;
    transition: $transition-base;


    @include media-breakpoint-down(lg) {
      padding: 0.5rem 0.5rem;
    }

    &:hover {
      color: $gray-dark !important;
    }

    &:active {
      text-decoration: underline;
    }
  }

  &_hover {
    position: relative;

    &:hover {
      >.c-secondary-menu_dropdown {
        visibility: visible;
        padding-top: 5px;
        opacity: 1;
      }
    }
  }

  // The container for submenu relative is body
  &_dropdown {
    transition: $transition-base;
    visibility: hidden;
    opacity: 0;
    width: auto;
    height: auto;
    padding-top: 0;
    left: 0;
    background: $white;
    position: absolute;
    transform: translateY(-2px);
    z-index: 101;
    background: $light;
    box-shadow: 0 130px 130px rgba(0, 0, 0, 0.15);

    // @include media-breakpoint-up(md) {
    //   top: 135px;
    // }

    // @include media-breakpoint-up(lg) {
    //   top: 100px;
    // }

    &_title {
      font-family: $font-family-ttcommons-bold !important;
    }
  }

  &_footer {
    margin: 7px 0;

    a {
      font-size: $font-size-sm;
      display: inline-block;
      padding: 0.5rem 0.4rem;

      &.favorite {
        background: url(../assets/images/icon-favorite.svg) no-repeat 0px 9px;
        padding-left: 24px !important;
      }
    }
  }

  &_mobile {
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;

    &_parent>a,
    .mlp-current-language-item {
      display: none;
    }

    .nav-link {
      font-size: $font-size-base !important;
    }

    .nav-item {
      margin-bottom: 0;
    }
  }
}