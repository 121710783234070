/** Gravity Forms */
.gform_wrapper form {
  margin-bottom: 0;
}

.gform_wrapper ul {
  @extend .list-unstyled;
}

.gform_wrapper li {
  @extend .form-group;
}

.gform_wrapper .gfield_required {
  padding-left: 1px;
  color: $danger;
}

.gform_wrapper .gfield_radio li {
  line-height: 27px;
}

.gform_title {
  @extend .h2;
  text-align: center;
}

.gform_wrapper input[type="email"],
.gform_wrapper input[type="date"],
.gform_wrapper input[type="datetime"],
.gform_wrapper input[type="datetime-local"],
.gform_wrapper input[type="month"],
.gform_wrapper input[type="number"],
.gform_wrapper input[type="password"],
.gform_wrapper input[type="search"],
.gform_wrapper input[type="tel"],
.gform_wrapper input[type="text"],
.gform_wrapper input[type="time"],
.gform_wrapper input[type="week"],
.gform_wrapper input[type="url"],
.gform_wrapper select,
.gform_wrapper textarea {
  @extend .form-control;
}

.gform_wrapper {
  .gfield {
    margin-bottom: $spacer * 1.5;

    &_label {
      margin-bottom: $spacer * 0.25;
      @extend .bold;
      color: $dark;
      font-size: 1rem;
    }

    &_radio {
      padding-top: $spacer * 0.5;

      .gfield_radio {
        label {
          padding-left: 8px;
        }
      }

      li {
        margin-bottom: $spacer;

        input:first-child {
          margin: 0.3rem 0.6rem 0.3rem 0;
          width: 1rem;
          float: left;
        }

        label {
          float: left;
          margin-bottom: 0;
          font-weight: $font-weight-base;
          color: $black;
          width: calc(100% - 1.6rem);
        }
      }
    }
  }
}

.gform_wrapper .button {
  @extend .btn;
  @extend .btn-primary;
}

.gform_wrapper .gfield_error {
  .gfield_label {
    @extend .alert-danger;
  }

  input,
  select,
  textarea {
    @extend .alert-danger;
  }
}

.validation_error {
  @extend .alert;
  @extend .alert-danger;
}

.validation_message {
  display: none;
}

.gform_button {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.gform_validation_container,
.gform_wrapper .gform_validation_container,
body .gform_wrapper li.gform_validation_container,
body .gform_wrapper .gform_body ul.gform_fields li.gfield.gform_validation_container,
body .gform_wrapper ul.gform_fields li.gfield.gform_validation_container {
  display: none !important;
  position: absolute !important;
  left: -9000px;
}

.hidden_label label.gfield_label {
  display: none;
}

.gfield_visibility_hidden {
  display: none;
}

.gform_validation_errors {
  margin-bottom: 1.5rem !important;
}

.gform_wrapper .gform_footer {
  display: block !important;
}

.gfield_label {
  font-weight: 400 !important;
}

.gfield_checkbox,
.ginput_container_radio {
  @include clearfix();
}

.gfield_checkbox li,
.ginput_container_consent {
  display: block;
  margin-bottom: 0;
  clear: left;

  input {
    margin: 0.3rem 0.6rem 0.3rem 0;
    width: 1rem;
    float: left;
  }

  label {
    float: left;
    width: calc(100% - 1.6rem);
  }
}

.ginput_container_textarea {
  textarea.small {
    height: 4rem;
  }

  textarea.medium {
    height: 8rem;
  }

  textarea.large {
    height: 12rem;
  }
}



// Only dealerfinder
#gform_fields_31 {
  .ginput_complex {
    justify-content: center;
  }

  .gfield {
    text-align: left;
  }

  #input_31_10_3_container label,
  #input_31_10_6_container label,
  #input_31_7_1_container label,
  #input_31_7_3_container label,
  #input_31_7_5_container label {
    font-weight: bold;

    &:after {
      content: "(*)";
      display: inline-block;
      font-weight: bold;
      color: #c02b0a;
    }
  }

  .gfield_required.gfield_required_text {
    font-size: 0;
    // overflow: hidden;

    &:after {
      content: "(*)";
      font-size: 12px;
      font-weight: bold;
      color: #c02b0a;
    }
  }
}