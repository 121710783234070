.c-trends {
  @include media-breakpoint-up(md) {
    margin-top: 130px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 150px;
  }
  &_slider {
    @include media-breakpoint-up(md) {
      transform: translateY(-80px);
    }
    @include media-breakpoint-up(lg) {
      transform: translateY(-110px);
    }
  }
}
