// BeRocket forget this thing as fast as possible

// Container
.c-filtering {

  border: 3px solid $gray-dark;
  border-radius: $border-radius-sm;
  padding: 2.1rem 1.5rem 2.1rem 1.3rem;
  margin: 2rem 0;

  .berocket_element_above_products {
    @include media-breakpoint-up(sm) {
      display: flex;
    }
  }
}

.bapf_sfilter {
  margin-bottom: 0 !important;
}

.berocket_aapf_widget {
  margin: 0 0 1rem 0 !important;
}

// BeRocket Wrapper per item
.berocket_single_filter_widget {
  position: relative;

  @include media-breakpoint-up(sm) {
    margin-right: 1rem;
  }

  .bapf_head h3 {
    @extend .btn;

    background: $cherry;
    background: white;
    color: $gray-dark;
    border-color: $cherry;
    text-align: center;

    @include media-breakpoint-up(sm) {
      margin-bottom: 0 !important;
    }
  }

  .bapf_ccolaps .bapf_head h3 {
    @extend .btn;
    background: $cherry;
    color: $white;
  }

  .bapf_body {
    position: relative !important;
    height: auto;
    margin: 0;
    padding: 1rem;
    opacity: 1;
    visibility: visible;

    @include media-breakpoint-down(sm) {
      transform: translate3d(0, 0, 0) !important;
      top: 100% !important;
    }

    @include media-breakpoint-up(sm) {
      position: absolute !important;
      top: 100%;
      transform: translateY(-8px);
      left: 0;
      margin-top: 15px;
      min-width: 195px;
      padding: 1rem 1rem 0.5rem;
      background: $white;
      box-shadow: 0 12px 50px rgba(0, 0, 0, 0.2);
      border-radius: $border-radius-sm;
      z-index: 99999;
      transition: $transition-base;
    }
  }

  // Filtergroup
  .berocket_single_filter_widget {}

  // Title
  .berocket_aapf_widget-title_div {
    padding: 0 !important;

    .btn {
      font-size: $font-size-base;
      font-family: $font-family-ttcommons-regular;
      min-width: 130px;
    }
  }

  // ul List with items
  .berocket_color_text {
    margin-left: 1rem !important;
  }
}

// Updated image + color selector
.bapf_img_span,
.bapf_clr_span {
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  border: none !important;
  position: relative;
  transform: rotate(0) !important;

  .berocket_checkbox_color:hover &:after {
    opacity: 0.3;
  }

  .berocket_checked &:after {
    opacity: 1 !important;
  }
}

.bapf_clr_span {
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  border: none !important;
  position: relative;
  transform: rotate(0) !important;

  .berocket_checkbox_color:hover &:after {
    opacity: 0.3;
  }

  .berocket_checked &:after {
    opacity: 1 !important;
  }
}

.checked .bapf_img_span,
.checked .bapf_clr_span {

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background: url(../assets/images/icon-check-white.svg) no-repeat center center;
    transition: $transition-base;
    z-index: 1000;
  }

}

// BeRocket Active filtering
.berocket_aapf_widget_selected_area {

  @include clearfix();

  @include media-breakpoint-up(sm) {
    display: flex !important;
  }

  ul {
    margin-bottom: 0 !important;
  }

  li {
    margin-right: 0.5rem !important;

    a {
      border-radius: $border-radius-sm;
      padding: 4px 26px 4px 10px;
      border: $secondary 1px solid;
      background: $white;
      color: $secondary;
      background: url(../assets/images/icon-cross.svg) no-repeat 90% center;
      background-size: 12px;
      transition: $transition-base;

      &:hover {
        color: darken($secondary, 10%);
      }
    }
  }

  .berocket_aapf_widget_selected_filter {
    display: flex !important;

    .bapf_sfa_taxonomy {
      margin: 0 0.5rem 0.5rem 0;
    }

    span {
      display: none;
    }

    li {
      a {
        margin: 0;
        padding: 1px 26px 1px 10px;
        color: $white;
        background-color: $secondary;
        border: $secondary 1px solid;
        transform: translateY(-2px);
        background-image: url(../assets/images/icon-cross-white.svg);

        &:hover {
          background-color: darken($secondary, 10%);
        }
      }
    }
  }

}