.c-categories {
  list-style: none;
  .cat-item:not(.current-cat) a {
    margin: 0 0.5rem;
    @extend .btn;
    @extend .btn-outline-primary;
  }
  .current-cat a {
    margin: 0 0.5rem;
    @extend .btn;
    @extend .btn-primary;
  }
}
