/*
  Project: Vivafloors
  Author: Thomas
 */

.c-favorite-circle {
  cursor: pointer;
  display: block;

  &:hover {
    circle {
      fill: $cherry;
    }

    path {
      fill: $white;
    }
  }
}

.c-favorite-icon-before {
  transform: translateY(-3px);
  display: inline-block;
  margin-right: 5px;
}

.c-favorite-icon {
  position: relative;

  .c-header-desktop & {
    transform: translateY(3px);
  }

  &__nr {
    position: absolute;
    right: -11px;
    top: -6px;
    background: $black;
    color: $white;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    text-align: center;
    font-size: 10px;
    font-family: $font-family-ttcommons-bold;
    line-height: 14px;
  }

  &__favorited {
    .inside {
      display: block !important;
    }
  }
}

//.c-header .c-favorite-icon a {
//  background: url(../assets/images/icon-favorite.svg) no-repeat;
//  background-position: 8px 11px;
//  background-size: 15px auto;
//  position: relative;
//  padding-left: 27px;
//  border-radius: 50%;
//

//}
//
//.c-header-mobile__container .c-favorite-icon a {
//  width: 30px;
//  height: 30px;
//  border-radius: 50%;
//  background-position: center center;
//  display: block;
//
//  .c-favorite-icon__nr {
//    top: 0;
//  }
//}

//.c-secondary-menu_mobile .c-favorite-icon a {
//  background-position: 12px 16px;
//  padding-left: 37px;
//}


@include media-breakpoint-up(md) {
  .c-header .c-favorite-icon.c-favorite-icon__favorited a {
    background-position: 11px 11px;
    padding-left: 37px;
    border-radius: 0;
  }
}

.c-favorite-modal {
  border-radius: 100%;
  padding: 6px 7px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: none;

  &:active {
    background: url(../assets/images/icon-favorite-overview.svg) no-repeat center center !important;
  }

  .c-product-tease &,
  ul.products & {
    position: absolute;
    right: 23px;
    top: 10px;
    height: 30px;
    width: 32px;
    padding: 5px;
    z-index: 3;
    background: url(../assets/images/icon-favorite-overview.svg) no-repeat center center;
    background-size: 22px auto;
  }
}

.c-favorite {
  visibility: hidden;
  display: none;

  &.active+.c-favorite-modal,
  &.loading+.c-favorite-modal,
  &.active:active+.c-favorite-modal {
    background: url(../assets/images/icon-favorite-overview-active.svg) no-repeat center center;
  }

  &.active+.btn-heart .inside {
    display: block !important;
  }

  &.loading+.c-favorite-modal {
    animation: heartbeat 1s infinite;
  }
}


@keyframes heartbeat {
  0% {
    transform: scale(.75);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(.75);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(.75);
  }

  100% {
    transform: scale(.75);
  }
}