@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      @for $i from 1 through 4 {
        .w#{$infix}-#{$i * 25} {
          width: #{$i * 25%} !important;
        }
        .h#{$infix}-#{$i * 25} {
          height: #{$i * 25%} !important;
        }
  
        .min-w#{$infix}-#{$i * 25} {
          min-width: #{$i * 25%} !important;
        }
        .min-h#{$infix}-#{$i * 25} {
          min-height: #{$i * 25%} !important;
        }
  
        .max-w#{$infix}-#{$i * 25} {
          max-width: #{$i * 25%} !important;
        }
        .max-h#{$infix}-#{$i * 25} {
          max-height: #{$i * 25%} !important;
        }
      }
  
      @for $i from 0 through 20 {
        .w#{$infix}-#{$i} {
          width: ($spacer * $i) !important;
        }
  
        .h#{$infix}-#{$i} {
          height: ($spacer * $i) !important;
        }
      }

      @for $i from 1 through 20 {
        .w#{$infix}-#{$i * 50}px {
          width: #{$i * 50}px !important;
        }
  
        .h#{$infix}-#{$i * 50}px {
          height: #{$i * 50}px !important;
        }
      }
  
      .w#{$infix}-auto {
        width: auto !important;
      }
  
      .h#{$infix}-auto {
        height: auto !important;
      }
    }
  }
  