.c-list {
  counter-reset: list;
  margin-bottom: 0;

  &-number {
    & > * {
      margin-bottom: $spacer;
      position: relative;
      padding-left: 55px;
      display: block;
      counter-increment: list;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        display: block;
        position: absolute;
        content: counter(list);
        border: 2px solid $gray-middle;
        font-family: $font-family-ttcommons-bold;
        color: $gray-middle;
        width: 30px;
        height: 30px;
        line-height: 17px;
        font-size: 15px;
        text-align: center;
        left: 0;
        top: 0;
        border-radius: 50%;
        padding: 5px;
      }
    }
  }
}

article ul{
  padding-left: 28px;

  li {
    list-style-position: outside;
  }
}
