.c-usps {
  list-style-type: none;
  width: 100%;
  padding-left: 0;

  &__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    & > div {
      :first-child {
        margin-right: 10px;
      }
    }

    a {
      color: $black;
      &:hover {
        text-decoration: underline !important;
        color: $gray-900 !important;
      }
    }
  }

  &-full {
    a {
      &:hover {
        text-decoration: underline !important;
        color: $gray-400 !important;
      }
    }
  }

}
