/** WooCommerce Checkout */

form.woocommerce-checkout {
  margin-bottom: 0;

  .required {
    padding-left: 1px;
    color: $danger;
    text-decoration: none;
  }

  input[type="email"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="week"],
  input[type="url"],
  select,
  textarea {
    @extend .form-control;
    width: 100%;
  }

  input[type="radio"] {
    clear: left;
    margin-top: -0.1rem;
  }

  .woocommerce-billing-fields__field-wrapper,
  .woocommerce-terms-and-conditions-wrapper {
    padding: 0 5px;
  }

  input[readonly] {
    color: $gray-800  !important;
    background-color: $gray-light  !important;
  }

  .form-row {
    margin-bottom: $spacer * 1.5;

    &.place-order {
      display: block;
    }

    label:not(.radio) {
      margin-bottom: $spacer * 0.25;
      @extend .bold;
      width: 100%;
      color: $dark;
    }

    &:not(.thwcfd-field-radio) .woocommerce-input-wrapper {
      width: 100%;
    }

    &#other_variation_field,
    &#other_from_where_field,
    &#other_advice_field {

      padding-top: $spacer * 0.5;
      margin-bottom: $spacer;

      label {
        margin-bottom: $spacer * 0.75;
      }

      .woocommerce-input-wrapper {
        display: flex;
        flex-wrap: wrap;

        input {
          width: 16px;
        }

        label {
          padding: 0 0 0.25rem 0.5rem;
          margin-bottom: 0;
          width: calc(100% - 28px - 0.5rem);
        }
      }
    }
  }

  .woocommerce-terms-and-conditions-wrapper {

    .form-row {
      flex-wrap: nowrap;
    }

    label {
      padding: 0;
      transform: translateX(20px);
      cursor: default;
    }

    input {
      transform: translateY(-3px) translateX(10px);
      cursor: pointer;
    }
  }

  .button {
    @extend .btn;
    @extend .btn-primary;
  }

  .woocommerce-error {
    margin-bottom: 2rem;
    padding: 0;

    li {
      list-style: none;
      @extend .alert;
      @extend .alert-danger;
    }
  }
}