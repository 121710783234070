.c-favorite-checkboxes {
  position: relative;
  &.loading {
    &:before {
      content: '';
      display: block;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background: rgba($black, 0.1) url('../assets/images/ajax-loader.gif') no-repeat center center;
      z-index: 1;
    }
  }
}

.c-favorite-checkbox {
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 100%;
      margin-bottom: 1rem;
      position: relative;
      @include media-breakpoint-up(md) {
        width: calc(50% - (#{$grid-gutter-width}/2));

        &:nth-child(odd) {
        margin-right: $grid-gutter-width;

        }
      }
      input {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
      label {
        background: $gray-lighter;
        padding: 1rem;
        width: 100%;
        border-radius: $border-radius-sm;
        img {
          width: 50px;
          height: 50px;
          margin-right: 15px;
        }
      }
    }
  }

  &__message_max,
  &__message_min {
    display: none;
    &.show {
      display: block;
    }
  }
}
