.js-show-more {
    display: flex;

    &.show-more {
        .js-show-more-content {
            max-height: 100%;
        }

        .js-show-more-button {
            span {
                &:nth-child(2) {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .js-show-more-content {
        max-height: 400px;
        overflow: hidden;
        transition: max-height 0.5s ease-in-out;
    }

    .js-show-more-button {
        span {
            &:nth-child(2) {
                svg {
                    transform: rotate(0);
                    transform-origin: center;
                    transition: 0.25s transform ease-in-out;
                }
            }
        }
    }
}