/* Customize the label (the container) */
.custom-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 110%;
  user-select: none;
  transition: 0.2s ease-in-out all;
  padding: 10px 15px 10px 45px;
  border: solid 2px $gray-300;
  border-bottom: none;

  text-align: left;

  &:hover {
    background: #EEE;
  }

  &:first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

  }

  &:last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    margin-bottom: 1rem;
    border-bottom: solid 2px $gray-300;
  }

}

/* Hide the browser's default radio button */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 15px;
  left: 12px;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
  transition: 0.2s ease-in-out all;
}

/* On mouse-over, add a grey background color */
.custom-radio:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.custom-radio input:checked~.checkmark {
  background-color: $cherry;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio .checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}