.c-floornumber {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 0 4px;
  display: block;
  font-size: $small-font-size;
  background: rgba(256,256, 256, 0.7);
  border-radius: 2px;
  transition: $transition-base;

  @include media-breakpoint-up(md) {
    right: 15px;
    bottom: 15px;
  }

  &:hover {
    text-decoration: none;
    background: rgba(256,256, 256, 1);
  }
}
