@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  
      .lh#{$infix}-base {
        line-height: $line-height-base * 1 !important;
      }
  
      .lh#{$infix}-sm {
        line-height: $line-height-base * 0.8 !important;
      }
  
      .lh#{$infix}-lg {
        line-height: $line-height-base * 1.25 !important;
      }
  
      .lh#{$infix}-xl {
        line-height: $line-height-base * 1.5 !important;
      }
  
      .lh#{$infix}-xxl {
        line-height: $line-height-base * 1.75 !important;
      }
    }
  }