/*
  Project: Vivafloors
  Author: Thomas
 */

.c-read-more {
  font-family: $font-family-ttcommons-demibold;

  &:after {
    content: '';
    background: url(../assets/images/icon-arrow.svg) no-repeat 0 5px;
    display: inline-block;
    height: 1rem;
    width: 15px;
    margin-left: 10px;
    background-size: 100% auto;
    transform: rotate(0deg);
  }

  &[aria-expanded='false'] {
    .if-not-collapsed {
      display: none;
    }
  }

  &[aria-expanded='true'] {
    .if-collapsed {
      display: none;
    }
    &:after {
      transform: rotate(180deg);
    }
  }
}

