.top {
    top: 0;
}

.end {
    right: 0;
}

.bottom {
    bottom: 0;
}

.start {
    left: 0;
}