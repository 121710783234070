.woocommerce-billing-fields__field-wrapper {

  &__message_max,
  &__message_min {
    display: none;

    &.show {
      display: block;
    }
  }

  #dealer_products {
    position: relative;
    min-height: 160px;
  }

  &.loading {
    #dealer_products_field {
      position: relative;

      &:before {
        content: '';
        display: block;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        background: rgba($black, 0.1) url('../assets/images/ajax-loader.gif') no-repeat center center;
        z-index: 1;
      }
    }
  }
}