.c-flags {
    >a {
        background: no-repeat left top;
        background-size: 16px auto;
        background-position: 10px center;
        padding-left: 30px !important;
    }

    .c-secondary-menu & {
        >a {
            padding: 10px 10px 10px 30px;
        }
    }

    &-parent {
        // border-right: 1px solid white;
    }

    &-de>a {
        background-image: url(../assets/images/flags/de.png);
    }

    &-nl>a {
        background-image: url(../assets/images/flags/nl.png);
    }

    &-lt>a {
        background-image: url(../assets/images/flags/lt.png);
    }

    &-en>a {
        background-image: url(../assets/images/flags/gb.png);
    }
}