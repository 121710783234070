/*
  Project: Vivafloors
  Author: Thomas
 */

.c-search {
  margin-top: 4px;

  &-input {
    border: none;
    padding: 8px 0 4px 30px;
    min-width: 170px;
    width: 170px;
    background: url(../assets/images/icon-search.svg) no-repeat left 55%;
    background-size: 18px auto;
    font-size: 1.1rem;

    .c-mobile-menu & {
      font-size: 1.8rem;
      background-size: 28px auto;
      padding: 8px 0 4px 38px;
      min-width: 230px;
      width: 230px;
    }

    @include media-breakpoint-up(lg) {
      min-width: 230px;
      width: auto;
    }

    &::placeholder {
      color: $secondary;
    }

    .c-filtering__search & {
      min-width: 100px;
      transition: $transition-base;

      &:focus {
        min-width: 230px;

        @include media-breakpoint-up(lg) {
          min-width: 300px;
        }
      }
    }
  }
}