.woocommerce {
  &-message {
    @extend .alert;
    @extend .alert-warning;
  }

  &-info {
    @extend .alert;
    @extend .alert-info;
  }

  &-error {
    @extend .alert;
    @extend .alert-danger;
    list-style: none;

    li:last-child {
      margin-bottom: 0;
    }
  }
}
