@keyframes strike {
    0% {
        width: 0;
    }

    40% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

.c-strike-through {
    position: relative;

    &:after {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 3px;
        background: $cherry;
        animation-name: strike;
        animation-duration: 2s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
}