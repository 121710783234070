/*
  Project: Vivafloors
  Author: Thomas
 */

// Still not proud but its wordpress you know
.c-product-tease {
  h4 a {
    transition: $transition-base;
  }

  &:hover h4 a {
    color: $cherry !important;
  }
}

.product-not-available-label {
  background: $secondary;
  color: #fff;
  position: absolute;
  left: 0;
  top: 35px;
  z-index: 99;
  padding: 0.2rem 0.9rem 0.2rem 0.7rem;
  border-radius: 0 25px 25px 0;
  font-size: 1rem;

  &_small {
    top: 20px;
    padding: 0.16rem 0.7rem 0.16rem 0.5rem;
    font-size: 0.9rem
  }
}