.c-recycle-label {
    background: #009933;
    padding: 10px 17px 10px 12px;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;

    @include media-breakpoint-up(xl) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 6px 17px 10px 12px;

        &:hover {
            padding-top: 10px;
        }
    }

    &:hover {
        background: #03b43e;
    }

    &_icon {
        max-height: 76px;
    }

    &_text {
        font-size: 15px;

        @include media-breakpoint-up(xl) {
            font-size: 14px;
        }

        * {
            line-height: 1.2;
        }
    }
}