a {
  &:hover {
    span {
      border-bottom: 0;
    }
  }
}

a.offset-anchor {
  display: block;
  position: relative;
  top: -170px;
  visibility: hidden;
}
