.u-img-push-right,
.u-img-push-left {
  img {
    height: auto !important;
    width: 100% !important;
  }
  @include media-breakpoint-up(md) {
    width: calc(100% + 60px);
  }
  @include media-breakpoint-up(xxl) {
    width: calc(100% + 120px);
  }
}

.u-img-push-left {
  @include media-breakpoint-up(md) {
    margin-left: -60px;
  }
  @include media-breakpoint-up(xxl) {
    margin-left: -120px;
  }
}

.u-img-push-right {
  @include media-breakpoint-up(md) {
    margin-left: -20px;
  }
  @include media-breakpoint-up(xxl) {
    margin-left: 20px;
  }
}

