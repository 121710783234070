.woocommerce-product-attributes {
  &-item__label {
    padding-right: 10px;
    padding-bottom: 10px;
  }

  &-item__value {
    padding-bottom: 10px;

    p {
      margin-bottom: 0;
    }
  }
}
