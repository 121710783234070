/*
  Project: Vivafloors
  Author: Thomas
 */

.c-find-salespoint {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-up(lg) {
    padding-top: 5px;
  }
  &_link {
    background: url(../assets/images/icon-pin.svg) no-repeat left center;
    font-family: $font-family-ttcommons-demibold;
    padding-left: 24px;
  }
}
