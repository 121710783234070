/*
  Project: Vivafloors
  Author: Thomas
 */

.h1-small {
  font-size: $font-size-base * 2;

  @include media-breakpoint-up(md) {
    font-size: $font-size-base * 2.2;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-base * 2.2;
  }
}

.h2-small {
  font-size: $font-size-base * 1.4;
  line-height: 1.2;

  @include media-breakpoint-up(md) {
    font-size: $font-size-base * 1.65;
  }

}

.h4-bigger {
  font-size: $font-size-base * 2;

  @include media-breakpoint-up(md) {
    font-size: $h3-font-size;
  }
}

.h4-strike {
  font-size: $h4-font-size;

  @include media-breakpoint-up(md) {
    font-size: $h3-font-size;
    font-weight: bold;
  }

  // @include media-breakpoint-up(xl) {
  //   font-size: $h2-font-size;
  //   font-weight: bold;
  // }
}

h3,
.h3 {
  font-family: $font-family-ttcommons-bold;
  color: $cherry;
}

h4,
.h4 {
  font-family: $font-family-sans-serif;
  color: $gray-dark;
}

.lead {
  font-family: $font-family-blackerdisplay-bold;
  color: $gray-middle;
}

.bold {
  font-family: $font-family-ttcommons-bold;
}

.medium {
  font-family: $font-family-ttcommons-medium;
}

.normal {
  font-family: $font-family-ttcommons-regular;
}

.smaller {
  font-size: 80%;
}